<template>
  <div :class="$style.block">
    <slot />
  </div>
</template>

<style module lang="scss">
.block {
  padding: 32px;
  border-radius: 32px;
  border: 1px solid var(--payments-block-border);
  background: var(--payments-block-background);

  @media screen and (max-width: 475px) {
    padding: 24px 20px;
    border-radius: 24px;
  }
}
</style>
