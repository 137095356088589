<template>
  <div
    :class="[$style.item, selected && $style.selected]"
    @click="select"
  >
    <slot />
    {{ item }}
  </div>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    item: string;
    selected?: boolean;
  }>(),
  {
    selected: false,
  },
);

const emits = defineEmits(["update:select"]);
const select = () => {
  emits("update:select", props.item);
};
</script>

<style module lang="scss">
.item {
  padding: 16px;
  font-size: var(--payments-base-select-search-query-font-size);
  font-weight: 500;
  line-height: 16px;
  border-radius: var(--payments-base-select-item-border-radius);
  display: flex;
  align-items: center;
  gap: var(--payments-base-select-inner-gap);
  cursor: pointer;
  transition: 0.25s;

  &:hover {
    background: var(--payments-base-select-option-active);
  }
}

.selected {
  background: var(--payments-base-select-option-active);
}
</style>
