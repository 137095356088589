<template>
  <div :class="$style.separator"></div>
</template>

<style module>
.separator {
  background: var(--payments-separator);
  height: 0.6px;
}
</style>
