<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.68404 4.78125C2.98721 4.78125 3.29038 4.89324 3.5217 5.11799L9 10.4414L14.4783 5.11799C14.9409 4.66926 15.691 4.66926 16.1536 5.11799C16.6155 5.56749 16.6155 6.2962 16.1536 6.7457L9.83765 12.8822C9.37501 13.3309 8.62499 13.3309 8.16234 12.8822L1.84639 6.7457C1.38454 6.2962 1.38454 5.56749 1.84639 5.11799C2.07771 4.89324 2.38088 4.78125 2.68404 4.78125Z"
      fill="#F5F5FA"
    />
  </svg>
</template>
