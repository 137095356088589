<template>
  <div
    ref="el"
    :class="$style.wrapper"
    :tabIndex="tabIndex"
  >
    <div
      :class="[$style.selected, isOpen && $style.active]"
      :data-test="$attrs['data-test'] && $attrs['data-test'] + '-select'"
      @click="isOpen = !isOpen"
    >
      <div
        v-if="!slots.selectedText"
        :class="$style.selectedText"
      >
        {{ selectedText }}
      </div>
      <slot
        v-else
        name="selectedText"
      />
      <div
        :class="$style.arrow"
        :data-test="$attrs['data-test'] && $attrs['data-test'] + '-arrow'"
      >
        <IconChevronDown2 :class="[$style.down, isOpen && $style.up]" />
      </div>
    </div>
    <div
      v-show="isOpen"
      ref="items"
      :class="[$style.items, !visibility && $style.hidden]"
      :style="{
        width: itemsWidth,
        [direction]: 0,
      }"
      :data-test="$attrs['data-test'] + '-options'"
    >
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
import { BaseSelectDefaultProps } from "./BaseSelect.props";
import useMobileCheck from "~/composables/useMobileCheck";

const slots = useSlots();
let isMobile: boolean = false;

if (isClient()) {
  isMobile = useMobileCheck();
}

defineOptions({
  inheritAttrs: false,
});

const props = withDefaults(
  defineProps<{
    tabIndex?: number;
    selectedText: string;
    parentEl?: string;
  }>(),
  BaseSelectDefaultProps,
);

defineEmits(["update:select"]);
const isOpen = ref(false);

const el = ref<HTMLElement | null>(null);
const items = ref<HTMLElement | null>(null);
const calcWidth = () => {
  if (!isMobile) return;
  if (!props.parentEl) return;

  const parentEl = el.value?.closest(`[data-id="${props.parentEl}"]`);

  const itemsWidth = parentEl
    ? parentEl.getBoundingClientRect().width + "px"
    : "100%";

  return itemsWidth;
};

let itemsWidth: string | undefined = undefined;
let direction = ref<"left" | "right">("right");

const visibility = ref(false);

const setDirection = () => {
  const bounding = items.value?.getBoundingClientRect();

  if (!bounding) return;

  if (bounding.left < 0) {
    direction.value = "left";
  } else if (window.innerWidth - bounding.right < 0) {
    direction.value = "right";
  }

  visibility.value = true;
};

onMounted(() => {
  itemsWidth = calcWidth();

  /**
   * Скрываем открытый селект при клике на области вне его
   */
  const handleClickOutside = (event: MouseEvent) => {
    const wrapper = el.value;
    if (wrapper && !wrapper.contains(event.target as Node)) {
      isOpen.value = false;
    }
  };
  document.addEventListener("click", handleClickOutside);

  // Удаляем обработчик при размонтировании компонента
  onBeforeUnmount(() => {
    document.removeEventListener("click", handleClickOutside);
  });
});

watch(
  () => props.selectedText,
  () => {
    isOpen.value = false;
  },
);

watch(
  () => isOpen.value,
  (value) => {
    if (value) {
      nextTick(() => {
        setDirection();
      });
    } else {
      visibility.value = false;
    }
  },
);
</script>

<style lang="scss" module>
.wrapper {
  position: relative;
  user-select: none;
}

.selected {
  border-radius: var(--payments-base-select-button-border-radius);
  border: 1px solid var(--payments-base-select-border);
  color: var(--general-white);
  height: 48px;
  background: var(--payments-base-select-background);
  transition: 0.25s;
  display: flex;
  padding-left: 20px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  &:hover {
    background: var(--payments-base-select-background-hover);
  }
  &:active {
    background: var(--payments-base-select-background-pressed);
  }

  &Text {
    @media screen and (max-width: 350px) {
      white-space: nowrap;
      width: 70px;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

.active {
  background: var(--payments-base-select-background-active);
}

.arrow {
  border-left: var(--payments-base-select-arrow-border-left);
  width: 46px;
  height: 100%;
  display: flex;
  align-items: center;
  margin-left: 20px;
  justify-content: center;
}

.down {
  transform: rotate(0deg);
  transition: 0.25s;
}

.up {
  transform: rotate(180deg);
}

.items {
  position: absolute;
  transition:
    opacity 0.4s linear,
    transform 0.2s ease-in;
  z-index: 1000;
  padding: 8px;
  margin-top: var(--payments-base-select-items-margin-top);
  border-radius: var(--payments-base-select-dropdown-border-radius);
  background: var(--payments-base-select-dropdown-background);
  width: 343px;
  max-width: 343px;
  max-height: 390px;
  right: 0;
  border: var(--payments-base-select-dropdown-border);
  overflow-y: scroll;

  @media screen and (max-width: 375px) {
    width: 100%;
  }

  > * + * {
    margin-top: 8px;
  }
}

.hidden {
  visibility: hidden;
  opacity: 0;
  transform: translateY(-30px);
}
</style>
