export type BaseSelectItem = {
  title: string;
  value: string;
};

export type BaseSelectItems = {
  selectedItem?: BaseSelectItem;
  items: BaseSelectItem[];
  tabIndex?: number;
  parentEl?: string;
};

export const BaseSelectDefaultProps = {
  tabIndex: 0,
} as const;
