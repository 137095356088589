<template>
  <Block :class="$style['support-help']">
    <Heading
      level="h4"
      :class="$style.heading"
    >
      {{ t("misc.need_help") }}
    </Heading>
    <BaseButton
      size="l"
      variant="tonal"
      @click="userStore.openSupport"
    >
      {{ t("faq.chat_with_support") }} <IconSupport />
    </BaseButton>
  </Block>
</template>

<script setup lang="ts">
import Block from "~/components/Balance/Block.vue";
import Heading from "~/components/Shared/Heading.vue";
import BaseButton from "~/components/Shared/Base/BaseButton.vue";

const { t } = useI18n();
const userStore = useUserStore();
</script>

<style lang="scss" module>
.support-help {
  margin: 16px auto;
  border-radius: 24px;
  @media screen and (max-width: 425px) {
    text-align: center;
  }
  h4 {
    font-weight: 400;
  }
}
.heading {
  margin-bottom: 24px;
  @media screen and (max-width: 834px) {
    margin-bottom: 18px;
  }
}
</style>
